<template>
    <v-container fluid fill-height v-bind:style="{ 'background-image': 'url(' + bgImg + ')' }"  class="bg-container">
        <v-container>
            <v-row class="text-center">
                <v-col cols="12" sm="2"></v-col>
                <v-col cols="12" sm="8" class="sign-primary-hd-col">
                    <h1 class="primary-hd">SETTINGS</h1>
                    <h2 class="menu-hd"><router-link to="/profile">My Profile</router-link></h2>
                    <h2 class="menu-hd" @click="Logout"><a>Logout</a></h2>
                    <div class="buttons-wrapper">
                        <v-btn fab text x-large class="mr-5" >
                            <v-icon class="custom-icon" x-large>help_outline</v-icon>
                        </v-btn>
                        <v-btn fab text x-large >
                            <span class="custom-icon material-icons">
                                <span @click="setMute(false)" v-if="!mute">volume_up</span>
                                <span @click="setMute(true)" v-if="mute">volume_off</span>
                            </span>
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="12" sm="2"></v-col>
            </v-row>
            <v-row align="end" class="footer">
                <v-col cols="0" sm="2">
                </v-col>
                <v-col cols="4" sm="2">
                    <BackBtn @close="goBack()"></BackBtn>
                </v-col>
                <v-col cols="4" sm="4" class="text-center">
                    <p class="footer-hd">Privacy</p>
                </v-col>            
                <v-col cols="4" sm="4" class="bottom-logo">
                    <v-img :src="require(`@/assets/2B-with-Beaker.png`)"></v-img>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import background from '@/assets/white-science-icon-background.jpg';
import BackBtn from '@/components/BackBtn';

export default {
    name: "Settings",
    components:{
        BackBtn
    },
    data() {
        return {
            bgImg: background,
            mute: this.$store.state.mute
        }
    },
    created(){
        this.mute = this.$store.state.mute
    },
    methods: {
        setMute(isMute){
            if(isMute){
                this.mute = false;
            }else{
                this.mute = true;
            }
            this.$store.state.mute = !isMute;
        },
        Logout(){
            this.$store.dispatch('userLogout');
        },
        goBack(){
            this.$router.go(-1);
        }
    }
}
</script>

<style scoped>
    .primary-hd{
        font-size: 3.5rem;
        letter-spacing: 5px;
        margin-top: 2.5rem;
    }

    .menu-hd{
        margin-top: 2.3rem;
        line-height: 1;
    }

    .menu-hd a{
        font-size: 2.5rem;
        letter-spacing: 2px;
        margin-top: 2.3rem;
        line-height: 1;
        color: #fff;
        text-shadow: 5px 5px 5px rgba(0,0,0,0.5);
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
    }

    .menu-hd a:hover{
        color: #23C033;
    }

    .footer-hd:hover{
        cursor: pointer;
        color: #23C033;
    }

    .footer-hd{
        color: #fff;
        font-size: 1.3rem;
        text-shadow: 5px 5px 5px rgba(0,0,0,0.5);
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
    }

    .bottom-logo{
        margin-top: -7.5rem;
    }

    .back-icon{
        height: 45px;
        width: 60px;
    }

    .back-icon:hover{
        cursor: pointer;

    }

    .custom-icon{
        font-size: 50px;
    }

    
    .buttons-wrapper{
        margin-top: 2.5rem;
    }

    .bg-container{
        background-size: cover;
        font-family: 'Lomo', sans-serif;
    }
   
</style>